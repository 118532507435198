import React, { useState } from 'react'
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    Snackbar,
    Theme,
    Typography
} from "@material-ui/core";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { red } from '@material-ui/core/colors';
import ShareIcon from "@material-ui/icons/Share";
import ArocketsIcon from "../components/ArocketsIcon";
import CloseIcon from '@material-ui/icons/Close';
import SocialShare from "../components/SocialShare";
import ReactGA from "react-ga";

const Clipboard = require("clipboard-polyfill");
const useStyles = makeStyles((theme: Theme) => ({
    area: {
        minHeight: 120,
        height: '100%',
    },
    gridItem: {
        flexBasis: 0
    },
    buttonLabel: {
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    wrapIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex'
    },
    alert: {
        borderRadius: "15px !important"
    }
}))

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function getFontSizeToFit(text: string, fontFace: string) {
    const ctx = document.createElement('canvas').getContext('2d');
    if (ctx) {
        ctx.font = `1px ${fontFace}`;
        return ctx.measureText(text).width;
    }
}

function getTxtAsImage(text: string): string | null {
    const FONT_SIZE = 14
    const textFit = getFontSizeToFit(text, "sans-serif")
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext('2d');
    if (ctx) {
        canvas.width = FONT_SIZE * (textFit || 16)
        canvas.height = FONT_SIZE
        ctx.textBaseline = 'middle';
        ctx.fillStyle = "#69C9D0";
        ctx.font = `normal 100 ${FONT_SIZE}px sans-serif`;
        ctx.fillText(text, 0, FONT_SIZE / 2 + 2);
        return canvas.toDataURL();
    }
    return null
}

const PromocodePreview = (props: any) => {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false);
    const [like, setLike] = React.useState(false);
    const [copied, setCopied] = React.useState(false);

    const promocodeBase64 = getTxtAsImage(props.promocode.toUpperCase())

    const onClickPromocode = (promocode: string) => {
        promocode && Clipboard.writeText(promocode)
            .then(() => {
                setOpen(true)
                setCopied(true)
                window.navigator.vibrate && window.navigator.vibrate(200);
                ReactGA.event({
                    category: "promocode",
                    action: "copy",
                    label: promocode
                })
                ReactGA.event({
                    category: "store-copy",
                    action: props.storeName,
                    label: promocode
                })
            })
            .catch(() => null)
    }

    const [share, setShowShare] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <React.Fragment>
            <Snackbar open={open} autoHideDuration={1500} onClose={handleClose}>
                <Alert className={classes.alert} onClose={handleClose} severity="success">
                    Промокод скопирован
                </Alert>
            </Snackbar>
            <Card elevation={1} className={classes.area} style={{
                backgroundColor: copied ? '#efefef' : 'auto'
            }}>
                {share && (
                    <React.Fragment>
                        <CardHeader
                            avatar={
                                <ArocketsIcon/>
                            }
                            action={
                                <IconButton onClick={() => {
                                    setShowShare(false)
                                }} aria-label="settings">
                                    <CloseIcon/>
                                </IconButton>
                            }
                            title="Поделиться"
                            subheader="твои друзья оценят"
                        />
                        <CardContent>
                            <SocialShare
                                title={`Воспользуйся промокодом ${props.promocode} в магазине ${props.storeName} (${props.storeHost}) - ${props.description}`}
                                url={`https://app.arockets.ru${props.path}#${props.promocode}`}/>
                        </CardContent>
                    </React.Fragment>
                )}
                {!share && (
                    <Grid container direction="column" justify="flex-end" alignItems="stretch" style={{
                        height: '100%'
                    }}>
                        <React.Fragment>
                            <Grid className={classes.gridItem} item sm={12} style={{
                                flex: 2
                            }}>
                                <CardContent>
                                    <Typography variant="subtitle1" gutterBottom>
                                        <Box fontWeight="fontWeightBold" mt={1} mb={1}>
                                            {props.description}
                                        </Box>
                                    </Typography>
                                </CardContent>
                            </Grid>
                            <Grid className={classes.gridItem} item sm={12}>
                                <CardContent>
                                    <Box mt={2}>
                                        <Button variant="outlined" color="primary" fullWidth
                                                startIcon={props.is_partner ? <ThumbUpIcon/> : null}
                                                onClick={() => {
                                                    onClickPromocode(props.promocode)
                                                }}
                                                classes={{ label: classes.buttonLabel }}
                                        >
                                            {promocodeBase64 && (
                                                <img alt="promocode"
                                                     src={promocodeBase64}/>
                                            )}
                                            {!promocodeBase64 && (
                                                props.promocode
                                            )}
                                        </Button>
                                    </Box>
                                    {props.active_till && (
                                        <Box mt={1}>
                                            <Typography variant="overline" display="block" gutterBottom>
                                                <AccessTimeIcon viewBox="2 0 24 24"
                                                                className={classes.wrapIcon}/> {props.active_till}
                                            </Typography>
                                        </Box>
                                    )}
                                </CardContent>
                            </Grid>
                            <Grid className={classes.gridItem} item sm={12}>
                                <Divider variant="middle"/>
                                <CardActions disableSpacing>
                                    <IconButton onClick={() => {
                                        ReactGA.event({
                                            category: "like",
                                            action: props.storeName,
                                            label: props.promocode
                                        })
                                        setLike(true)
                                    }} aria-label="add to favorites">
                                        <FavoriteIcon style={{ color: like ? red[500] : '' }}/>
                                    </IconButton>
                                    <IconButton onClick={() => {
                                        setShowShare(true)
                                        ReactGA.event({
                                            category: "share",
                                            action: "trying",
                                            label: props.storeName
                                        })
                                    }} aria-label="share">
                                        <ShareIcon/>
                                    </IconButton>
                                </CardActions>
                            </Grid>
                        </React.Fragment>
                    </Grid>
                )}
            </Card>
        </React.Fragment>
    )
}

export default PromocodePreview
