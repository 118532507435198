import React, { Component } from 'react'
import { connect } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroller"
import { Loading } from "../components/Loading";
import ImageLoader from "../components/ImageLoader";
import StorePreview from "../components/StorePreview";
import ContentLayout from "../layout/ContentLayout";
import ToolbarLayout from "../layout/ToolbarLayout";
import { bindActionCreators } from "redux";
import { updateSearchStoreQuery } from "../store/actions";
import { filterShops } from "../components/utils";
import { locationCategory } from "../store/utils";
import { AnimateSwap } from "../components/AnimateSwap";

const mapStateToProps = (state: any) => {
    return {
        list: state.shopsList,
        searchQuery: state.searchStoreQuery,
        appStatus: state.appStatus
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        updateSearchQuery: updateSearchStoreQuery
    }, dispatch)
}

class Stores extends Component<any, any> {
    private readonly observer = new ImageLoader("img.image-loading")
    private unlisten = null

    constructor(props: any) {
        super(props);

        this.state = {
            limit: 20,
            offset: 0,
            hasMore: false,
            categoryQuery: locationCategory(this.props.location)
        }

        this.loadMore = this.loadMore.bind(this)
    }


    loadMore() {
        const currentCount = this.currentStoresLength()
        let nextCount = currentCount + this.state.limit
        const data = this.listData(nextCount)
        if (data.length < nextCount) {
            this.setState({
                hasMore: false
            })
        } else {
            this.setState({
                offset: this.state.offset + 1
            })
        }
    }

    listData(count: number = this.currentStoresLength()) {
        const query = this.props.searchQuery || ""
        const list = this.props.list || []
        let shops = [...list]
        if (this.state.categoryQuery && !query) {
            let filter = this.state.categoryQuery.toLowerCase()
            // FIXME move to config
            if (filter === "Разное".toLowerCase()) {
                filter = ""
            }
            shops = shops.filter(item => {
                if (!filter) return item.category.length === 0
                return (item.category || []).join(",").toLowerCase().indexOf(filter) > -1
            })
        }
        shops = filterShops(shops, query).slice(0, count)
        return shops
    }

    componentDidMount(): void {
        this.setState({
            hasMore: true
        })
        this.onChangeCategoryQueryQuery()
        this.unlisten = this.props.history.listen((location: any) => {
            this.setState({
                categoryQuery: this.getCategoryQuery(location),
            })
        });
    }

    componentWillUnmount() {
        if (this.unlisten) {
            // @ts-ignore
            this.unlisten();
        }
    }

    onChangeCategoryQueryQuery() {
        this.setState(this.getNewCategoryState())
    }

    getNewCategoryState() {
        return {
            categoryQuery: this.getCategoryQuery(),
            offset: 0,
            hasMore: true
        }
    }

    getCategoryQuery(location?: any) {
        return locationCategory(location || this.props.location)
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.searchQuery !== this.props.searchQuery) {
            this.setState({
                offset: 0,
                hasMore: true
            })
        }
    }

    currentStoresLength() {
        const limit = this.state.limit
        const offset = this.state.offset
        return limit * (offset + 1)
    }

    render() {
        const list = this.listData()

        const items = list.map((item: any) => (
            <Grid item xs={12} md={6} lg={4} key={item.uuid}>
                <StorePreview {...item}/>
            </Grid>
        ))

        setTimeout(() => {
            this.observer.observe();
        }, 200)

        return (
            <ToolbarLayout location={this.props.location} storeName="global"
                           updateSearchQuery={this.props.updateSearchQuery}>
                <ContentLayout
                    breadcrumbsTitle={this.props.searchQuery ? `~  ${this.props.searchQuery}` : this.state.categoryQuery}
                    title="Магазины"
                    loading={this.props.appStatus === "loading"}
                >
                    <InfiniteScroll
                        hasMore={this.state.hasMore}
                        loadMore={this.loadMore}
                        initialLoad
                        loader={
                            <div className="text-center" key="loading">
                                <Loading/>
                            </div>
                        }
                        useWindow
                        getScrollParent={() => document.getElementById('shop-list')}
                    >
                        {list.length > 0 && (
                            <AnimateSwap>
                                <Grid container spacing={2} id="shop-list" style={{
                                    marginTop: 0,
                                }}>
                                    {items}
                                </Grid>
                            </AnimateSwap>
                        )}
                        {list.length === 0 && (
                            <Typography variant="h6" style={{ color: "white" }}>
                                Нет мaгазинов
                            </Typography>
                        )}
                    </InfiniteScroll>
                </ContentLayout>
            </ToolbarLayout>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stores)
