import React from 'react'
import { connect } from "react-redux";
import { Button, Grid, Typography } from "@material-ui/core";
import PromocodePreview from "../components/PromocodePreview";
import ToolbarLayout from "../layout/ToolbarLayout";
import { bindActionCreators } from "redux";
import { updateSearchPromocodeQuery } from "../store/actions";
import NotFound from "./404";
import ContentLayout from "../layout/ContentLayout";
import { getStoreData } from "../api/arockets";
import CustomPromocodeView from "../components/CustomPromocodeView";
import { filterShops } from "../components/utils";
import StorePreview from "../components/StorePreview";
import ImageLoader from "../components/ImageLoader";
import { prepareHost } from "../store/utils";

const mapStateToProps = (state: any) => {
    return {
        list: state.shopsList,
        searchQuery: state.searchPromocodeQuery || ""
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        updateSearchQuery: updateSearchPromocodeQuery
    }, dispatch)
}

class StoreView extends React.Component<any, any> {
    private readonly observer = new ImageLoader("img.image-loading")

    constructor(params: any) {
        super(params);
        const { match: { params: { host, prefix } } } = this.props
        this.state = {
            host: prefix ? [host, prefix].join("/") : host,
            storeExternalLink: "https://arockets.ru",
            store: params.store || null,
            status: params.status || 'loading'
        }
    }

    async componentDidMount() {
        await this.fetchStore()
    }

    async componentDidUpdate() {
        await this.fetchStore()
    }

    async fetchStore() {
        const { list } = this.props
        if (list.length > 0) {
            if (this.state.status === 'loading') {
                const host = prepareHost(this.state.host)
                const store = list.find((item: any) => prepareHost(item.host) === host)
                const updatingState: any = {
                    status: 'ready',
                    store: null,
                }
                if (store) {
                    updatingState.store = await getStoreData(store.uuid)
                    updatingState.storeExternalLink = store.a_link
                }
                this.setState(updatingState)
            }
        }
    }

    getPromocodesItems() {
        const store = this.state.store
        const query = this.props.searchQuery

        return store?.promocodes
            .filter((item: any) => {
                return (item.promocode + item.description).toLowerCase().indexOf(query.toLowerCase()) > -1
            })
            .map((item: any) => (
                <Grid item xs={12} md={6} lg={4} key={item.promocode}>
                    <PromocodePreview
                        storeName={store.name}
                        storeHost={store.host}
                        path={this.props.location.pathname}
                        {...item}
                    />
                </Grid>
            )) || []

    }

    getShopsPreview() {
        return filterShops(this.props.list, this.props.searchQuery)
            .slice(0, 3)
            .map(item => (
                <Grid item xs={12} md={6} lg={4} key={item.uuid}>
                    <StorePreview elevation={1} {...item}/>
                </Grid>
            ))
    }

    render() {
        const store = this.state.store
        const ready = this.state.status === 'ready'

        if (ready && store === null) {
            return <NotFound/>
        }

        const items = this.getPromocodesItems()
        const shopsPreview = this.getShopsPreview()

        this.observer.observe();

        return (
            <ToolbarLayout location={this.props.location} storeName={store?.name}
                           updateSearchQuery={this.props.updateSearchQuery}>
                <ContentLayout breadcrumbsTitle={store?.name}
                               storeLink={this.state.storeExternalLink}
                               title={store && store.name} loading={!ready}
                >
                    {items.length === 0 && (
                        <React.Fragment>
                            <Grid item>
                                <Typography variant="h6" style={{ color: "black" }}>
                                    Нет активных промокодов по фильтру
                                    "{this.props.searchQuery}"
                                </Typography>
                            </Grid>
                        </React.Fragment>
                    )}

                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container spacing={3} id="shop-list" style={{
                                marginTop: 0,
                            }}>
                                {items.length > 0 && (
                                    items
                                )}
                                <Grid item xs={12} md={6} lg={4}>
                                    <CustomPromocodeView/>
                                </Grid>
                            </Grid>
                        </Grid>
                        {shopsPreview.length > 0 && (
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={3}>
                                    {shopsPreview}
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container spacing={3} justify="center">
                                <Grid item xs={12} md={6} lg={4}>
                                    <Button fullWidth href="/?ref=store-view"
                                            size="large"
                                            variant="contained"
                                            color="primary">
                                        Каталог
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ContentLayout>
            </ToolbarLayout>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreView)
