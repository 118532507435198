import {
    Badge,
    Box,
    Breadcrumbs,
    Card,
    CardActionArea,
    CardMedia,
    Grid,
    Link,
    Theme,
    Typography,
    withStyles
} from "@material-ui/core";
import { pageContent, pageMainTitle } from "../components/utils/styles";
import React from "react";
import { Loading } from "../components/Loading";
import Footer from "../components/Footer";
import { Link as RouterLink } from "react-router-dom";
import Guide from "../components/tour/Guide";

const styles = (theme: Theme) => {
    return Object.assign({
            anchorOriginTopRightRectangle: {
                right: 40,
                top: -5
            },
            margin: {
                width: '100%'
            }
        },
        pageMainTitle(theme),
        pageContent(theme)
    )
};

function randomInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

class ContentLayout extends React.Component<any, any> {

    render() {
        const { classes, breadcrumbsTitle } = this.props;
        const { storeLink } = this.props || 'https://arockets.ru'

        return (
            <React.Fragment>
                <Guide/>
                <Box pl={3} pr={3} pt={2} pb={2} className={classes.pageMainTitle}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h1" noWrap>
                                <Link href={storeLink ? storeLink + "?source=app.arockets.ru" : "#"}
                                      target={storeLink ? "_blank" : ""}
                                      underline="none"
                                      color="inherit">
                                    {this.props.title || '...'}
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box ml={3} mr={3} mb={3} className={classes.pageContent}>
                    {this.props.loading && (
                        <Loading/>
                    )}
                    {!this.props.loading && (
                        <React.Fragment>
                            <Grid container>
                                <Grid item lg={12} xs={12} md={12}>
                                    <Box mb={1}>
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Link
                                                underline="none"
                                                style={{ color: "white" }}
                                                to="/"
                                                component={RouterLink}>
                                                Магазины
                                            </Link>
                                            {breadcrumbsTitle && (
                                                <Typography color="textPrimary">
                                                    {breadcrumbsTitle}
                                                </Typography>
                                            )}
                                        </Breadcrumbs>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item lg={12} xs={12} md={12}>
                                    <Badge color="secondary"
                                           badgeContent={"Реклама"}
                                           className={classes.margin}
                                           component="div"
                                           classes={{
                                               anchorOriginTopRightRectangle: classes.anchorOriginTopRightRectangle
                                           }}
                                    >
                                        <Card style={{
                                            width: '100%'
                                        }}>
                                            <CardActionArea component="a" href="/store/foodbasket.pro"
                                                            target="_blank">
                                                <CardMedia
                                                    component="img"
                                                    alt="Contemplative Reptile"
                                                    // height="60"
                                                    image={`https://arockets.ru/public/img/promobanners/foodbasket/728%D1%8590/${randomInteger(1, 3)}.png`}
                                                    title="Реклама Foodbasket"
                                                />
                                            </CardActionArea>
                                        </Card>
                                    </Badge>
                                </Grid>
                            </Grid>
                            {this.props.children}
                        </React.Fragment>
                    )}
                    <Footer/>
                </Box>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(ContentLayout)
